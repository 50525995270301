function handleIntersection(entries) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.play();
    } else {
      entry.target.pause();
    }
  });
}

function setupIntersectionObserver(videos) {
  const observer = new IntersectionObserver(handleIntersection, {
    threshold: 1.0,
  });

  videos.forEach((video) => {
    observer.observe(video);
  });
}

function setupPlayPauseToggle(videos) {
  videos.forEach((video) => {
    video.addEventListener("click", function () {
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    });
  });
}

function enableAutoPlay(videos) {
  setupIntersectionObserver(videos);
  setupPlayPauseToggle(videos);
}

function isReducedMotionEnabled() {
  return window.matchMedia("(prefers-reduced-motion: reduce)").matches;
}

function enableControls(videos) {
  videos.forEach((video) => video.setAttribute("controls", "controls"));
}

function setupDisplaySettings(videos) {
  if (isReducedMotionEnabled()) {
    enableControls(videos);
  } else {
    enableAutoPlay(videos);
  }
}

function initVideoAnimation() {
  const videos = document.querySelectorAll(".videoAnimation");
  setupDisplaySettings(videos);
}

initVideoAnimation();
